<template>
  <div class="home">



    <div class="content">
      <!-- 顶部 -->
      <Header :borderShow="true"  titleName="视频号" />


      
      <van-loading color="#888888" v-if="data.loading" vertical style="margin-top: 20vh;">加载中...</van-loading>



      <div class="list_empty" v-if="data.videoList.length == 0 && !data.loading">
        <img src="@/assets/images/list_empty.png" alt="">
        <div class="first_div">暂无内容~</div>
        <div class="last_div">请刷新页面或稍后尝试~</div>
      </div>



      <div class="news"  v-if="data.videoList.length > 0 && !data.loading">
        <div class="news_list">
          <div class="space_between" v-for="(item,index) in data.videoList" :key="index" @click="toNewDetails(item.id)"> 
                <img :src="item.imgUrl" alt="">
                <div class="right_box">
                    <div style="font-size: .4267rem;margin-bottom: .16rem;font-weight: 600;">{{item.title}}</div>
                    <div style="color: #ACACAC;" >
                        <div style="margin-bottom: .1rem;">{{item.source}}</div>
                        <span style="margin-right: .2rem;font-size: .2667rem;">{{filterTime(item.addTime)}}</span>
                        <span style="font-size: .2667rem;">浏览次数：{{item.click}}</span>
                    </div>
                </div>
            </div>
        </div>
      </div>

    </div>



    <!-- 底部tab切换 -->
    <!-- <Footer /> -->
  </div>
</template>

<script setup>
import Footer from "../../components/Footer.vue";
import { reactive, toRefs,onMounted,computed } from "vue";
import Header from "../../components/Header.vue";
import { useRoute, useRouter } from 'vue-router'
import { jsToCallApp } from '../../common/js/callApp1.0.0'
import Api from "../../utils/api";
const route = useRoute()
const router = useRouter()

let data = reactive({
  videoList: [],
  loading:true,
  current: 0,
  postData: {
    categoryId: 14,
    channelId: 1,
    pageSize: 100,
    pageIndex: 1,
  }
});

onMounted(() => {
  getNwesList()
  shareFn()
});

const shareFn = () => {
  const jsonString = {
    shareUrl: 'https://quanzong.cwmia.com/#/member/videoList',//分享链接
    shareCorver: 'https://quanzong.cwmia.com/logo.png',
    shareTitle: '中国职工保障互助会',
    shareDesc: '视频号',//分享描述
  }
  openAppFunction('setupShareData', { shareData: JSON.stringify(jsonString) })
}

function openAppFunction(type, jsonData) {
  const useTimeOut = setTimeout(() => {
    jsToCallApp(
      {
        type: type,
        ...jsonData,
      },
      (data) => {
        console.log(type, data)
      },
    )
    clearTimeout(useTimeOut);
  }, 300);
}

// 活动专区 - 新闻列表
const getNwesList = () => {
  Api.articleViewChannelNew(data.postData).then(res => {
    data.loading = false
    if (res.status == 200) {
      data.videoList = res.data
    }
  })
}
const filterTime= computed(() => (timestamp) => {
  timestamp = timestamp.replace(/-/g,'/')
  let time = new Date(timestamp)
  let year = time.getFullYear()
  let month = time.getMonth() + 1
  let date = time.getDate()


  if (month < 10) { month = '0' + month }
  if (date < 10) { date = '0' + date }

  return year + '-' + month + '-' + date 
})

const toNewDetails = (id) => {
  router.push({ path: '/news/details', query: { id, }})
};
</script>

<style lang='less' scoped>
/deep/ .van-tabs__wrap {
  border-radius: 10px;
}



.home {
  display: flex;
  flex-flow: column;
  height: 100%;
  font-size: 12px;

  .content {
    flex: 1;
    overflow-y: auto;
    background-color: #fff;

    .list_empty{
      text-align: center;
      padding-top: 15vh;
      img{
        width: 6.2933rem;
        height: 4.52rem;
      }
      .first_div{
          font-size: .4667rem;
          font-weight: 500;
          margin-top: .6667rem;
        }
        .last_div{
          font-size: .36rem;
          color: #ACACAC;
          margin-top: .2rem;

        }
    }
    .right_img{
      width: .16rem;
    }
    .news{
      background-color: #fff;
      padding: 0 .4rem;
      .news_list{
          >div{
            padding:.5333rem 0;
            border-bottom: .0133rem #ececec solid;
            img{
                width:4.12rem;
                height: 2.32rem;
                border-radius: .1867rem;
                object-fit: cover;
                margin-right: .3733rem;
            }
          }
        }
        .right_box{
            width: calc(100% - 4.5rem);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            
            height: 2.32rem;
        }
    }

  }
}
</style>